<script setup lang="ts">
    import 'vue3-carousel/dist/carousel.css'
    import { useBrandStore } from '~/stores/brandStore';
    import type { Brand } from '~/models/Brand';
    import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

    //store
    const brandStore = useBrandStore()
    
    //composable section
    const route = useRoute();
    const router = useRouter()

    const props=defineProps<{
        items:Brand[]
    }>()

    const changeBrand =(brand_id:number)=>{
        brandStore.setBrand(brand_id)

        if(route.path != '/store')
         router.push({path: '/store', query: {...route.query, brand :brand_id}})
    }

</script>
<template>
    <div class="!tw-w-full  tw-bg-white tw-flex tw-flex-row tw-gap-4 tw-text-black tw-px-4 tw-h-[120px]" >

    <div class="tw-w-full">            
        <Carousel
            :items-to-show="2"
            :itemsToScroll="1"
            :autoplay="1500"
            :transition="500"
            snapAlign="start"
            wrap-around
            :breakpoints="{
                640: {
                    itemsToShow: 3,
                    snapAlign: 'start'
                },
                1024: {
                    itemsToShow: 5,
                    snapAlign: 'start'}}"
        >
            <Slide v-for="item in items" :key="item.id">
                <div class="carousel__item"> <q-img :src="item.image_url" width="100px" height="100px" class="tw-cursor-pointer" @click="changeBrand(item?.id)"/></div>
            </Slide>        
        </Carousel>
        </div>
    </div>
</template>