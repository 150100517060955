export const useBrandStore = defineStore('brandStore',{
    state:()=>({
        brand:null as number|null
    }),
    getters:{
        getBrand(state){
            return state.brand
        }
    },
    actions:{
        setBrand(newBrand:number|null){
            this.brand = newBrand
        }
    }
})